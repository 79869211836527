import DataGrid from "../../../../shared/components/grid/DataGrid";
import { Metric } from "../../../api/types/portfolioMonitoringTypes";
import { columnDefinitions } from "./metricsGridDataProvider";

interface Props {
  metrics: Metric[];
  isLoading: boolean;
}

const MetricsGrid = ({ metrics, isLoading }: Props) => {
  return (
    <DataGrid<Metric>
      rows={metrics}
      columns={columnDefinitions}
      loading={isLoading}
      columnHeaderHeight={36}
      noRowsText="No metrics"
      disableColumnReorder
      disableColumnSorting
    />
  );
};

export default MetricsGrid;
