import { GridEventListener } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { DataCollectionSubmissionInfo } from "../../../../api/types/dataCollectionTypes";
import { pageRoutes } from "../../../../routes";
import { columnDefinitions } from "./dataSubmissionsGridDataProvider";

interface Props {
  rows: DataCollectionSubmissionInfo[];
  isLoading?: boolean;
  onRowsScrollEnd: () => void;
}

const DataSubmissionsGrid = ({ rows, isLoading, onRowsScrollEnd }: Props) => {
  const navigate = useNavigate();

  const handleCellClick: GridEventListener<"cellClick"> = ({ row }) => {
    navigate(`${pageRoutes.dataSubmissions}/${row.id}`);
  };

  return (
    <DataGrid<DataCollectionSubmissionInfo>
      rows={rows}
      columns={columnDefinitions}
      loading={isLoading}
      columnHeaderHeight={36}
      selectableRows
      onRowsScrollEnd={onRowsScrollEnd}
      onCellClick={handleCellClick}
      noRowsText="No data submissions"
      disableColumnReorder
      disableColumnSorting
    />
  );
};

export default DataSubmissionsGrid;
