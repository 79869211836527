import CheckIcon from "@mui/icons-material/CheckRounded";
import { Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { ContactWithFieldValues } from "../../../api/adminApi";
import { ObjectContact } from "../../../api/types/objectTypes";
import PortalRoleChip from "../../common/PortalRoleChip";

export interface ContactAccessMatrixRow extends ObjectContact {
  id: string;
  contactName: string;
  contactEmail: string;
}

export const getContactAccessMatrixRows = (
  objectContacts: ObjectContact[],
  allContacts: ContactWithFieldValues[]
): ContactAccessMatrixRow[] => {
  const allContactsMap = allContacts.reduce((result, contact) => {
    result.set(contact.id, contact);
    return result;
  }, new Map<string, ContactWithFieldValues>());

  return objectContacts.map((objectContact) => {
    const contact = allContactsMap.get(objectContact.contactId);
    return {
      ...objectContact,
      id: objectContact.contactId,
      contactName: contact?.name || "Unknown",
      contactEmail: contact?.email || "",
    };
  });
};

const getCategoryColumnDefinition = (category: string): GridColDef<ContactAccessMatrixRow> => ({
  field: category,
  headerName: category,
  flex: 1,
  minWidth: 160,
  renderCell: ({ row }) => (row.categories.includes(category) ? <CheckIcon color="primary" /> : null),
});

export const getColumnDefinitions = (categories: string[]): GridColDef<ContactAccessMatrixRow>[] => [
  {
    field: "contactName",
    headerName: "Name",
    flex: 3,
    minWidth: 160,
    renderCell: ({ row }) => (
      <Stack spacing={0.5} width="100%">
        <Typography variant="subtitle2">{row.contactName}</Typography>
        {row.contactEmail && (
          <Typography color="text.secondary" variant="caption">
            {row.contactEmail}
          </Typography>
        )}
      </Stack>
    ),
  },
  {
    field: "role",
    headerName: "Role",
    flex: 1,
    minWidth: 160,
    headerClassName: "with-right-border",
    cellClassName: "with-right-border",
    renderCell: ({ row }) => <PortalRoleChip role={row.role} />,
  },
  ...categories.map(getCategoryColumnDefinition),
];
