import { ListItemText, Select, Typography } from "@mui/material";
import MenuItemWithoutHighlight from "../../../shared/components/MenuItemWithoutHighlight";
import { PortalRole } from "../../api/types/accessTypes";
import PortalRoleChip from "./PortalRoleChip";

interface Props {
  roles: PortalRole | PortalRole[] | undefined;
  readonly?: boolean;
  onChange?: (roles: PortalRole[]) => void;
}

const noneRole = "None";

const PortalRoleSelector = ({ roles, onChange, readonly }: Props) => {
  return (
    <Select
      sx={{
        boxShadow: "none",
        ".MuiSelect-select": readonly ? { px: "0 !important", cursor: "default" } : {},
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        ".MuiSvgIcon-root": {
          display: readonly ? "none" : "block",
        },
      }}
      readOnly={readonly}
      value={roles ? (roles.length === 0 ? noneRole : roles) : ""}
      displayEmpty
      onChange={(event) => {
        if (event.target.value === "" || event.target.value === noneRole) {
          onChange?.([]);
        } else {
          onChange?.(Array.isArray(event.target.value) ? event.target.value : [event.target.value as PortalRole]);
        }
      }}
      renderValue={
        roles === undefined
          ? () => (
              <Typography variant="caption" color="textSecondary">
                Select
              </Typography>
            )
          : undefined
      }
    >
      <MenuItemWithoutHighlight value={noneRole}>
        <ListItemText primaryTypographyProps={{ component: "span" }} primary={<PortalRoleChip role={undefined} />} />
      </MenuItemWithoutHighlight>
      <MenuItemWithoutHighlight value="LP_Member">
        <ListItemText primaryTypographyProps={{ component: "span" }} primary={<PortalRoleChip role={"LP_Member"} />} />
      </MenuItemWithoutHighlight>
      <MenuItemWithoutHighlight value="LP_Admin">
        <ListItemText primaryTypographyProps={{ component: "span" }} primary={<PortalRoleChip role={"LP_Admin"} />} />
      </MenuItemWithoutHighlight>
    </Select>
  );
};

export default PortalRoleSelector;
