import ClearIcon from "@mui/icons-material/Clear";
import { Button, IconButton, Tooltip } from "@mui/material";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { OrganizationMemberInvitation } from "../../../../../api/types/userManagementTypes";
import EmptyMemberIcon from "../../../../common/EmptyMemberIcon";
import { useMembersContext } from "../MembersContext";
import { getDefaultGridSx } from "../definitions";
import { getPendingInvitesGridColumns } from "./membersGridDefinitions";

const PendingInvitesGrid = () => {
  const { filteredPendingInvites, isLoading, hasAccessToManageOrganizationUsers, deleteInvite, resendInvite } =
    useMembersContext();

  const columns = getPendingInvitesGridColumns();

  if (hasAccessToManageOrganizationUsers) {
    columns.push({
      field: "resend",
      headerName: "",
      width: 130,
      renderCell: ({ row }) => (
        <Button
          variant="outlined"
          onClick={() => resendInvite(row)}
          disabled={row.invitationStatus === "PendingApproval"}
        >
          Resend invite
        </Button>
      ),
    });
    columns.push({
      field: "actions",
      headerName: "",
      width: 30,
      cellClassName: "grid-row-actions",
      align: "right",
      renderCell: ({ row }) => (
        <Tooltip placement="top-start" title="Invalidate invite" arrow>
          <IconButton onClick={() => deleteInvite(row)} color="error">
            <ClearIcon />
          </IconButton>
        </Tooltip>
      ),
    });
  }

  return (
    <DataGrid<OrganizationMemberInvitation>
      getRowId={(row) => row.invitationId}
      sx={(theme) => getDefaultGridSx(theme)}
      columns={columns}
      rows={filteredPendingInvites}
      loading={isLoading}
      multilineRows
      slots={{
        noRowsOverlay: () => <EmptyMemberIcon title="No pending members" description="There are no pending members." />,
      }}
    />
  );
};

export default PendingInvitesGrid;
