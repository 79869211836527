import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Container, Popover, Stack } from "@mui/material";
import { useRef, useState } from "react";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import { EntityFieldType, ObjectClassDefinition } from "../../../../api/types/objectTypes";
import RecordCounter from "../../../common/filters/RecordCounter";
import { useObjectDefinitionContext } from "../ObjectDefinitionContext";
import DeleteFieldDialog from "./field-editor/DeleteFieldDialog";
import FieldEditor from "./field-editor/FieldEditor";
import FieldTypeSelectionList from "./field-editor/FieldTypeSelecttionList";
import { getColumnDefinitions, getRowEditButtonId } from "./objectFieldsGridDataProvider";
import {
  FieldEditForm,
  FieldRow,
  ObjectFieldsState,
  closeDialogAction,
  createFieldAction,
  deleteFieldAction,
  editFieldAction,
  getInitialState,
  openFieldTypeSelectorAction,
  searchAction,
  updateEditFormAction,
  updateFieldAction,
} from "./objectFieldsState";

const ObjectFields = () => {
  const { objectDefinition, onUpdateObjectDefinition, hasEditPermissions } = useObjectDefinitionContext();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [state, setState] = useState<ObjectFieldsState>(getInitialState(objectDefinition));

  const handleSearch = (value: string) => {
    setState(searchAction(value));
  };

  const handleNewFieldClick = () => {
    setState(openFieldTypeSelectorAction());
  };

  const handleSelectFieldType = (fieldType: EntityFieldType) => {
    setState(createFieldAction(fieldType));
  };

  const handleEditField = (field: FieldRow) => {
    setState(editFieldAction(field));
  };

  const handleDeleteField = (field: FieldRow) => {
    setState(deleteFieldAction(field));
  };

  const handleEditFormChange = (formUpdate: Partial<FieldEditForm>) => {
    setState(updateEditFormAction(formUpdate));
  };

  const handleFieldSaved = (fieldId: string, updatedObject: ObjectClassDefinition) => {
    onUpdateObjectDefinition(updatedObject);
    setState(updateFieldAction(fieldId, updatedObject));
  };

  const handleFieldDeleted = (fieldId: string, updatedObject: ObjectClassDefinition) => {
    onUpdateObjectDefinition(updatedObject);
    setState(updateFieldAction(fieldId, updatedObject));
  };

  return (
    <>
      <Container maxWidth="md" disableGutters sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <RecordCounter records={state.filteredRows.length} total={state.allRows.length} />
          <Stack direction="row" spacing={1}>
            <SearchField debounceTimeMs={300} onSearch={handleSearch} />
            {hasEditPermissions && (
              <Button
                ref={buttonRef}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleNewFieldClick}
                disabled={state.openDialog !== undefined}
              >
                New Field
              </Button>
            )}
          </Stack>
        </Box>

        <DataGrid<FieldRow>
          columns={getColumnDefinitions({ onEditField: handleEditField })}
          rows={state.filteredRows}
          noRowsText="No fields yet"
          sx={(t) => ({
            ".grid-cell-highlighted": {
              bgcolor: t.palette.action.selected,
            },
          })}
          disableColumnReorder
        />

        {["select_field_type", "edit_field"].includes(state.openDialog ?? "") && (
          <Popover
            open
            onClose={() => setState(closeDialogAction())}
            anchorEl={
              state.editedField
                ? (document.getElementById(getRowEditButtonId(state.editedField.id)) ?? buttonRef.current)
                : buttonRef.current
            }
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {state.openDialog === "select_field_type" && <FieldTypeSelectionList onSelect={handleSelectFieldType} />}
            {state.openDialog === "edit_field" && (
              <FieldEditor
                objectType={objectDefinition.objectType}
                editedField={state.editedField}
                form={state.fieldEditForm}
                readOnly={
                  !hasEditPermissions ||
                  Boolean(state.editedField?.source === "BusinessCentral") ||
                  Boolean(state.editedField?.source === "Portal")
                }
                onChange={handleEditFormChange}
                onDelete={handleDeleteField}
                onFieldSaved={handleFieldSaved}
                onClose={() => setState(closeDialogAction())}
              />
            )}
          </Popover>
        )}
      </Container>
      <DeleteFieldDialog
        objectType={objectDefinition.objectType}
        deletedField={state.deletedField}
        open={state.openDialog === "delete_field"}
        onClose={() => setState(closeDialogAction())}
        onFieldDeleted={handleFieldDeleted}
      />
    </>
  );
};

export default ObjectFields;
