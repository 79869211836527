import { useMemo } from "react";
import DataGrid from "../../../../shared/components/grid/DataGrid";
import { ContactAccessMatrixRow, getColumnDefinitions } from "./contactAccessMatrixGridDataProvider";

interface Props {
  categories: string[];
  rows: ContactAccessMatrixRow[];
  isLoading?: boolean;
}

const ContactAccessMatrixGrid = ({ categories, rows, isLoading }: Props) => {
  const columns = useMemo(() => getColumnDefinitions(categories), [categories]);

  return (
    <DataGrid<ContactAccessMatrixRow>
      rows={rows}
      columns={columns}
      loading={isLoading}
      columnHeaderHeight={36}
      pinnedColumns={{ left: ["contactName"] }}
      noRowsText="No contacts"
      multilineRows
      disableColumnReorder
      disableColumnSorting
      sx={(t) => ({
        ".MuiDataGrid-cell": {
          "&.with-right-border": {
            borderRight: `1px solid ${t.palette.divider}`,
          },
        },
        ".MuiDataGrid-columnHeader": {
          "&.with-right-border": {
            borderRight: `1px solid ${t.palette.divider}`,
          },
        },
      })}
    />
  );
};

export default ContactAccessMatrixGrid;
