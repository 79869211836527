import { Box, Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import SearchField from "../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { ObjectClassDefinition } from "../../../api/types/objectTypes";
import RecordCounter from "../../common/filters/RecordCounter";
import ContactAccessMatrixGrid from "./ContactAccessMatrixGrid";
import { getContactAccessMatrixRows } from "./contactAccessMatrixGridDataProvider";

interface Props {
  objectId: string;
  objectDefinition: ObjectClassDefinition;
}

const ContactAccessMatrix = ({ objectId, objectDefinition }: Props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const getObjectContacts = useCallback(
    () => adminApi.getObjectContacts(objectDefinition.objectType, objectId),
    [objectDefinition.objectType, objectId]
  );

  const [objectContacts, fetchObjectContactsError] = useFetch(getObjectContacts);

  const getAllContacts = useCallback(
    () =>
      adminApi.searchContacts({ fieldIds: [], includeFunds: false, includeInvestors: false, includeInboxData: false }),
    []
  );

  const [allContactsResp, fetchAllContactsError] = useFetch(getAllContacts);

  const allRows = useMemo(
    () => getContactAccessMatrixRows(objectContacts ?? [], allContactsResp?.items ?? []),
    [allContactsResp?.items, objectContacts]
  );

  const fetchError = fetchObjectContactsError || fetchAllContactsError;

  if (fetchError) {
    logError(fetchError, "[ContactAccessMatrix]");
    return <DataLoadingFailed title="Could not load contacts" />;
  }

  const handleSearch = (value: string) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const isLoading = objectContacts === undefined || allContactsResp === undefined;

  const rows = searchTerm
    ? allRows.filter(
        (row) =>
          row.contactName.toLowerCase().includes(searchTerm) || row.contactEmail.toLowerCase().includes(searchTerm)
      )
    : allRows;

  return (
    <Stack spacing={2.5} height="100%">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <RecordCounter records={rows.length} total={allRows.length} hide={isLoading} />
        <SearchField initialValue={searchTerm} debounceTimeMs={300} onSearch={handleSearch} />
      </Box>
      <ContactAccessMatrixGrid
        rows={allRows}
        categories={objectDefinition.supportedContactCategories}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default ContactAccessMatrix;
