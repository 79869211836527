import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { SentEmail } from "../../../../api/types/emailsTypes";
import { getColumns } from "./emailsTableDataProvider";

interface Props {
  apiRef?: React.MutableRefObject<GridApiPremium>;
  isLoading?: boolean;
  emails: SentEmail[];
  onRowScrollEnd?: () => void;
}

const EmailsTable = ({ apiRef, isLoading, emails, onRowScrollEnd }: Props) => {
  return (
    <DataGrid<SentEmail>
      apiRef={apiRef}
      loading={isLoading}
      columns={getColumns()}
      rows={emails}
      multilineRows
      noRowsText="No emails yet"
      disableColumnReorder
      onRowsScrollEnd={onRowScrollEnd}
    />
  );
};

export default EmailsTable;
