import { GridEventListener } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { Invoice } from "../../../../../api/adminApi";
import { useClientContext } from "../../../../../context/ClientContext";
import { pageRoutes } from "../../../../../routes";
import { InvoicesTab } from "../InvoicesTab";
import { getInvoicesListColumns } from "./invoicesGridProvider";

interface Props {
  invoices: Invoice[];
  loading: boolean;
  currentStatus: InvoicesTab;
  onRowsScrollEnd: () => void;
}

const InvoicesGrid = ({ invoices, loading, onRowsScrollEnd, currentStatus }: Props) => {
  const navigate = useNavigate();
  const { clientCode } = useClientContext();

  const handleCellClick: GridEventListener<"cellClick"> = ({ row, field }) => {
    if (field !== "actions") {
      navigate(`/${clientCode}/${pageRoutes.expenseManagement}/${pageRoutes.invoices}/${row.id}`);
    }
  };

  const hideStatusColumn = currentStatus === InvoicesTab.All;

  return (
    <DataGrid<Invoice>
      columns={getInvoicesListColumns(currentStatus)}
      getRowId={(row) => row.id}
      columnHeaderHeight={36}
      onRowsScrollEnd={onRowsScrollEnd}
      rows={invoices}
      noRowsText="No invoices yet"
      disableColumnReorder
      columnVisibilityModel={{
        status: !hideStatusColumn,
      }}
      loading={loading}
      selectableRows
      onCellClick={handleCellClick}
    />
  );
};

export default InvoicesGrid;
