import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { SentEmail } from "../../../../../api/types/emailsTypes";
import { getContactEmailsColumns } from "./contactEmailsTableDefinitions";

interface Props {
  emails: SentEmail[];
}

const ContactEmailsTable = ({ emails }: Props) => {
  return (
    <DataGrid<SentEmail>
      getRowId={(row) => row.id}
      noRowsText="No emails"
      columns={getContactEmailsColumns()}
      rows={emails}
      multilineRows
    />
  );
};

export default ContactEmailsTable;
