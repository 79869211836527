import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { EmailTrackingItem } from "../../../../../api/types/documentCollectionTypes";
import { getColumns } from "./emailsTableDataProvider";

interface Props {
  emails: EmailTrackingItem[];
}

const EmailsTable = ({ emails }: Props) => {
  return <DataGrid<EmailTrackingItem> columns={getColumns()} rows={emails} multilineRows />;
};

export default EmailsTable;
