import { useMemo } from "react";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { OrganizationDeactivatedMember } from "../../../../../api/types/userManagementTypes";
import EmptyMemberIcon from "../../../../common/EmptyMemberIcon";
import { useMembersContext } from "../MembersContext";
import { getDefaultGridSx } from "../definitions";
import { getDeactivatedMembersGridColumns } from "./membersGridDefinitions";

const DeactivatedMembersGrid = () => {
  const { filteredDeactivatedMembers, isLoading } = useMembersContext();

  const columns = useMemo(() => getDeactivatedMembersGridColumns(), []);

  return (
    <DataGrid<OrganizationDeactivatedMember>
      getRowId={(row) => row.userId}
      sx={(theme) => getDefaultGridSx(theme)}
      columns={columns}
      rows={filteredDeactivatedMembers}
      loading={isLoading}
      multilineRows
      slots={{
        noRowsOverlay: () => <EmptyMemberIcon title="No members" description="There are no deactivated members." />,
      }}
    />
  );
};

export default DeactivatedMembersGrid;
