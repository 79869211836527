import { GridColDef, GridGroupingColDefOverride, useGridApiRef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useMemo, useRef } from "react";
import { ApiError } from "../../../../../../shared/api/types";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { OrganizationReportInfo } from "../../../../../api/biApi";
import { getCheckedGridSx } from "../../../../common/grid/gridStyles";
import GroupHeader from "../../../../common/grid/GroupHeader";
import useGridExpand from "../../../reporting/reports/hooks/useGridExpand";
import { GridKey } from "../../../reporting/storage/reportsGridExpandStorage";
import { headerHeight } from "../CustomHeader";
import GroupCell from "./GroupCell";

interface Props {
  reports: OrganizationReportInfo[];
  loading: boolean;
  error: ApiError | undefined;
  getTreeDataPath: ((row: OrganizationReportInfo) => readonly string[]) | undefined;
  Toolbar: React.ReactNode;
  selectedIds: string[];
  currentReport: OrganizationReportInfo | undefined;
  gridKey: GridKey;
  setSelectedIds: (ids: string[]) => void;
  getColumns: (apiRef: React.MutableRefObject<GridApiPremium>) => GridColDef[];
  getViewReportUrl: (clientCode: string, reportId: string) => string;
}

const OrganizationReportsGridWrapper = ({
  reports,
  error,
  loading,
  getColumns,
  getTreeDataPath,
  Toolbar,
  selectedIds,
  setSelectedIds,
  currentReport,
  getViewReportUrl,
  gridKey,
}: Props) => {
  const apiRef = useGridApiRef();
  const showToolbar = useMemo(() => selectedIds.length > 0, [selectedIds]);
  const { isGroupExpandedByDefault, toggleSingleGridRow, toggleAllGridRows } = useGridExpand({ gridKey, apiRef });

  const groupingColDef = useMemo(
    (): GridGroupingColDefOverride => ({
      sortable: true,
      headerName: "Companies",
      width: 500,
      renderCell: (params) => (
        <GroupCell params={params} getViewReportUrl={getViewReportUrl} onExpandToggle={toggleSingleGridRow} />
      ),
      renderHeader: (params) => <GroupHeader params={params} onExpandToggle={toggleAllGridRows} />,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleSingleGridRow, toggleAllGridRows]
  );

  const columns: GridColDef[] = useMemo(() => getColumns(apiRef), [apiRef, getColumns]);

  const currentReportRef = useRef<OrganizationReportInfo>();
  currentReportRef.current = currentReport;

  if (error) {
    return <DataLoadingFailed text={error.message || "Failed to load reports"} />;
  }

  return (
    <DataGrid<OrganizationReportInfo>
      apiRef={apiRef}
      treeData
      getTreeDataPath={getTreeDataPath}
      columns={columns}
      rows={reports}
      getRowId={(row) => row.reportId}
      sx={(theme) => getCheckedGridSx(theme, showToolbar)}
      columnHeaderHeight={headerHeight}
      defaultGroupingExpansionDepth={2}
      loading={loading}
      checkboxSelection
      groupingColDef={groupingColDef}
      disableColumnReorder
      slots={{
        toolbar: () => (showToolbar ? Toolbar : null),
      }}
      getRowClassName={(params) => {
        if (apiRef.current.getRowNode(params.id)?.type === "group") {
          return "";
        }
        if (params.row.reportId === currentReportRef.current?.reportId) {
          return "Mui-hovered";
        }
        return "";
      }}
      onRowSelectionModelChange={(ids) => setSelectedIds(ids as string[])}
      sortingOrder={["asc", "desc"]}
      shiftActionsLeft
      isGroupExpandedByDefault={isGroupExpandedByDefault}
    />
  );
};

export default OrganizationReportsGridWrapper;
